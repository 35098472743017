import React from "react";

function AboutTestimonials() {
  return (
    <>
      <div className="testimonial-area ptb-100 bg-fbf9f7">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">TESTIMONIAL</span>
            <h2>Let's Meet With Our Client Says</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore.
            </p>
          </div>
          <div className="testimonial-slides owl-carousel owl-theme">
            <div className="single-testimonial-box">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-8">
                  <div className="testimonial-desc">
                    <i className="ri-double-quotes-l"></i>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy ant extra eirmod ut labore et dolore magna
                      aliquyam erat, sed diammi maxil voluptua. At vero eos et
                      accusam lores et ea rebum. Stet clitaiai to ankasd
                      gubergren, no sea takimata sanctus est Lorem ipsu adasta
                      na. Lorem ipsum dolor sit amet, consetetur sadipscing
                      elitr.
                    </p>
                    <div className="info">
                      <h3>Max Anderson</h3>
                      <span>CEO, Switch Color Ltd.</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="testimonial-image">
                    <img
                      src="assets/img/testimonial/testimonial1.jpg"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="single-testimonial-box">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-8">
                  <div className="testimonial-desc">
                    <i className="ri-double-quotes-l"></i>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy ant extra eirmod ut labore et dolore magna
                      aliquyam erat, sed diammi maxil voluptua. At vero eos et
                      accusam lores et ea rebum. Stet clitaiai to ankasd
                      gubergren, no sea takimata sanctus est Lorem ipsu adasta
                      na. Lorem ipsum dolor sit amet, consetetur sadipscing
                      elitr.
                    </p>
                    <div className="info">
                      <h3>Sarah Taylor</h3>
                      <span>Web Developer</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="testimonial-image">
                    <img
                      src="assets/img/testimonial/testimonial2.jpg"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="single-testimonial-box">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-8">
                  <div className="testimonial-desc">
                    <i className="ri-double-quotes-l"></i>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy ant extra eirmod ut labore et dolore magna
                      aliquyam erat, sed diammi maxil voluptua. At vero eos et
                      accusam lores et ea rebum. Stet clitaiai to ankasd
                      gubergren, no sea takimata sanctus est Lorem ipsu adasta
                      na. Lorem ipsum dolor sit amet, consetetur sadipscing
                      elitr.
                    </p>
                    <div className="info">
                      <h3>James Anderson</h3>
                      <span>Web Manager</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="testimonial-image">
                    <img
                      src="assets/img/testimonial/testimonial3.jpg"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape1">
          <img src="assets/img/shape/shape6.png" alt="img" />
        </div>
        <div className="shape2">
          <img src="assets/img/shape/shape7.png" alt="img" />
        </div>
      </div>
    </>
  );
}

export default AboutTestimonials;
