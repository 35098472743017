import React from "react";

function ServiceArchWhatWe() {
  return (
    <>
      <div className="what-we-do-area pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="what-we-do-image">
                <img src="assets/img/what-we-do/what-we-do1.jpg" alt="img" />
                <img
                  src="assets/img/shape/shape5.png"
                  alt="img"
                  className="shape"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="what-we-do-content">
                <span className="sub-title">SEE WHAT WE DO</span>
                <h2>Information is the Main Point to Success on Business</h2>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="project"
                    role="tabpanel"
                  >
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy ant extra eirmod tempor invidunt ut labore
                      et dolore magna aliquyam erat, sed diammi maxil voluptua.
                      At vero eos et accusam et justo duo dolores et ea rebum.
                      Stet clitaiai to ankasd gubergren, no sea takimata sanctus
                      est Lorem ipsum dolor sit amet. Adasta na lorem ipsum
                      dolor sit amet, consetetur sadipscing elitr.
                    </p>
                  </div>
                </div>
                <a href="/" className="default-btn">
                  Call Us <i className="ri-phone-line"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceArchWhatWe;
