import React from "react";

function CollegeSingleContent() {
  return (
    <>
      <div className="terms-conditions-area ptb-100">
        <div className="container">
          <div className="terms-conditions-content">
            <h3>Overview</h3>
            <p>
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, subscribe to the newsletter, fill out a form, and in
              connection with other activities, services, features or resources
              we make available on our Site. Users may be asked for, as
              appropriate, name, email address, mailing address, phone number,
              company name. We will collect personal identification information
              from Users only if they voluntarily consent such information to
              us. Users can always refuse to supply personally identification
              information, except that it may prevent them from engaging in
              certain Site related activities.
            </p>
            <h3>General permission to use and access and use limitations</h3>
            <p>
              This site is provided by <strong>Enry</strong>.{" "}
              <strong>Enry</strong> collects information in several ways from
              different parts of this site.
            </p>
            <p>
              <strong>1.</strong> Complimentary ground shipping within 1 to 7
              business days
              <br />
              <strong>2.</strong> In-store collection available within 1 to 7
              business days
              <br />
              <strong>3.</strong> Next-day and Express delivery options also
              available
              <br />
              <strong>4.</strong> Purchases are delivered in an orange box tied
              with a Bolduc ribbon, with the exception of certain items
              <br />
              <strong>5.</strong> See the delivery FAQs for details on shipping
              methods, costs and delivery times
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollegeSingleContent;
