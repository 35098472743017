import React from "react";

function ServiceMediaProjects() {
  return (
    <>
      <div className="projects-area ptb-100 bg-fbf9f7">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">COMPLETED PROJECTS</span>
            <h2>You Can Check Our Projects as Inspirations</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects1.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects1.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects2.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects2.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects3.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects3.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects4.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects4.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects5.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects5.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects6.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects6.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects7.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects7.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects8.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects8.jpg" alt="img" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <a
                  href="assets/img/projects/projects9.jpg"
                  className="popup-image"
                >
                  <img src="assets/img/projects/projects9.jpg" alt="img" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceMediaProjects;
