import React from "react";
import ServiceEducationHeader from "../Components/ServiceEducation/ServiceEducationHeader";
import ServiceEducationCards from "../Components/ServiceEducation/ServiceEducationCards";
import ServiceEducationFreeQuote from "../Components/ServiceEducation/ServiceEducationFreeQuote";

function ServiceEducation() {
  return (
    <>
      <ServiceEducationHeader />
      <ServiceEducationCards />
      <ServiceEducationFreeQuote />
    </>
  );
}

export default ServiceEducation;
