import React from "react";
import ServiceMediaHeader from "../Components/ServiceMedia/ServiceMediaHeader";
import ServiceMediaWhatWe from "../Components/ServiceMedia/ServiceMediaWhatWe";
import ServiceMediaService from "../Components/ServiceMedia/ServiceMediaService";
import ServiceMediaProjects from "../Components/ServiceMedia/ServiceMediaProjects";
import ServiceMediaContact from "../Components/ServiceMedia/ServiceMediaContact";

function ServiceMedia() {
  return (
    <>
      <ServiceMediaHeader />
      <ServiceMediaWhatWe />
      <ServiceMediaService />
      <ServiceMediaProjects />
      <ServiceMediaContact />
    </>
  );
}

export default ServiceMedia;
