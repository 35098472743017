/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function Navbar() {
  return (
    <>
      <div className="top-header-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <ul className="top-header-social-links d-flex align-items-center">
                <li>Follow us on:</li>
                <li>
                  <a href="/" target="_blank">
                    <i className="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <i className="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <i className="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <i className="ri-instagram-line"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-8 col-md-8">
              <ul className="top-header-contact-info">
                <li>
                  <i className="ri-time-line"></i>
                  <span>SUN - THU:</span> 8:00 AM - 9:00 PM
                </li>
                <li>
                  <i className="ri-map-pin-2-line"></i>
                  <span>OFFICE:</span> 1847 Morris Street, Texas
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-area">
        <div className="enry-responsive-nav">
          <div className="container">
            <div className="enry-responsive-menu">
              <div className="logo">
                <a href="/">
                  <img src="assets/img/logo-3.png" alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="enry-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="/">
                <img src="assets/img/logo-3.png" alt="logo" />
              </a>
              <div className="collapse navbar-collapse mean-menu">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="/" className="nav-link">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/about" className="nav-link">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="dropdown-toggle nav-link">
                      Services
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="/service-education" className="nav-link">
                          Education
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/service-media" className="nav-link">
                          Media
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/service-arch" className="nav-link">
                          Architecture
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="services-4.html"
                          className="dropdown-toggle nav-link"
                        >
                          Colleges
                        </a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <a href="/college-single" className="nav-link">
                              Kerala
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="/college-single" className="nav-link">
                              Tamil Nadu
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="/college-single" className="nav-link">
                              karnataka
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="/abroad" className="nav-link">
                      Abroad
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/blogs" className="nav-link">
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/contact" className="nav-link">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
