/* eslint-disable react/jsx-no-target-blank */
import React from "react";

function Footer() {
  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <a href="index.html" className="logo">
                  <img src="assets/img/white-logo.png" alt="img" />
                </a>
                <p>
                  Lorem ipsum dolor sit amet sadipscing elitr, sed diam no
                  tempor invidunt ut.
                </p>
                <div className="footer-contact-info">
                  <h5>Contact:</h5>
                  <ul>
                    <li>
                      <span>Call:</span>
                      <a href="tel:+(1)8144822296">+(1) 814 482 2296</a>
                    </li>
                    <li>
                      <span>Email:</span>
                      <a href="/cdn-cgi/l/email-protection#731b161f1f1c33161d010a5d101c1e">
                        <span
                          className="__cf_email__"
                          data-cfemail="3b535e5757547b5e55494215585456"
                        >
                          [email&#160;protected]
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget pl-4">
                <h3>Quick Links</h3>
                <ul className="links-list">
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                  <li>
                    <a href="products.html">Our Shop</a>
                  </li>
                  <li>
                    <a href="services-1.html">Services</a>
                  </li>
                  <li>
                    <a href="blog-details.html">Blog Details</a>
                  </li>
                  <li>
                    <a href="contact.html">Get A Quote</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget pl-2">
                <h3>Help Us</h3>
                <ul className="links-list">
                  <li>
                    <a href="about-simple.html">About Us</a>
                  </li>
                  <li>
                    <a href="terms-conditions.html">Help Center</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                  <li>
                    <a href="testimonial.html">Feedback</a>
                  </li>
                  <li>
                    <a href="blog-grid.html">Our Blog</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Opening Hours</h3>
                <ul className="opening-hours">
                  <li>
                    SUN - MON: <span>8:00 AM - 9:00 PM</span>
                  </li>
                  <li>
                    TUE: <span>8:00 AM - 9:00 PM</span>
                  </li>
                  <li>
                    WED: <span>8:00 AM - 9:00 PM</span>
                  </li>
                  <li>
                    THU: <span>8:00 AM - 9:00 PM</span>
                  </li>
                  <li>
                    FRI-SAT: <span>Closed</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-7 col-sm-6">
                <p>
                  Copyright @2021 Enry is Proudly Crafted by
                  <a href="/" target="_blank">
                    EnvyTheme
                  </a>
                </p>
              </div>
              <div className="col-lg-6 col-md-5 col-sm-6">
                <ul className="social-links">
                  <li>
                    <a href="/" target="_blank">
                      <i className="ri-facebook-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <i className="ri-twitter-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <i className="ri-linkedin-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <i className="ri-instagram-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
