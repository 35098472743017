import React from "react";
import ServiceEduSingleHeader from "../Components/ServiceEducation/ServiceEduSingle/ServiceEduSingleHeader";
import ServiceEduSingleContent from "../Components/ServiceEducation/ServiceEduSingle/ServiceEduSingleContent";
import ServiceEduSingleFreeQuote from "../Components/ServiceEducation/ServiceEduSingle/ServiceEduSingleFreeQuote";

function ServiceEduSingle() {
  return (
    <>
      <ServiceEduSingleHeader />
      <ServiceEduSingleContent />
      <ServiceEduSingleFreeQuote />
    </>
  );
}

export default ServiceEduSingle;
