import React from "react";
import HomeBanner from "../Components/Home/HomeBanner";
import HomeFreeQuote from "../Components/Home/HomeFreeQuote";
import HomeService from "../Components/Home/HomeService";
import HomeAbout from "../Components/Home/HomeAbout";
import HomeWhyChoose from "../Components/Home/HomeWhyChoose";
import HomeFunFacts from "../Components/Home/HomeFunFacts";
import HomeTestimonials from "../Components/Home/HomeTestimonials";
import HomePartners from "../Components/Home/HomePartners";
import HomeBlogs from "../Components/Home/HomeBlogs";

function Home() {
  return (
    <>
      <HomeBanner />
      <HomeService />
      <HomeAbout />
      <HomeWhyChoose />
      <HomeFreeQuote />
      <HomeFunFacts />
      <HomeTestimonials />
      <HomePartners />
      <HomeBlogs />
    </>
  );
}

export default Home;
