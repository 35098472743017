import React from "react";

function BlogContent() {
  return (
    <>
      <div className="col-lg-8 col-md-12">
        <div className="blog-details-desc">
          <div className="article-image">
            <img src="assets/img/blog/blog1.jpg" alt="img" />
          </div>
          <div className="article-content">
            <div className="entry-meta">
              <ul>
                <li>
                  <i className="ri-shield-user-line"></i>
                  <a href="blog-grid.html">Chris Orwig</a>
                </li>
                <li>
                  <i className="ri-calendar-2-line"></i>15 Jan, 2021
                </li>
              </ul>
            </div>
            <h3>The Secret of Your Business Success Find Quickly</h3>
            <p>
              Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
              Neque porro quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam dolor sit amet, consectetur adipisicing.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur
              <strong>adipisicing</strong> elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea <a href="/">commodo</a> consequat. Duis aute irure dolor in
              reprehenderit in sed quia non numquam eius modi tempora incidunt
              ut labore et dolore magnam aliquam quaerat voluptatem.
            </p>
            <p>
              Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
              Neque porro quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam dolor sit amet, consectetur adipisicing.
            </p>

            <h3>Four major elements that we offer:</h3>
            <ul>
              <li>Scientific skills for getting a better result</li>
              <li>Communication skills to getting in touch</li>
              <li>A career overview opportunity available</li>
              <li>A good work environment for work</li>
            </ul>
          </div>
          <div className="article-footer">
            <div className="article-share">
              <ul className="social">
                <li>
                  <span>Share:</span>
                </li>
                <li>
                  <a href="/" className="facebook" target="_blank">
                    <i className="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="/" className="twitter" target="_blank">
                    <i className="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="/" className="linkedin" target="_blank">
                    <i className="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="/" className="instagram" target="_blank">
                    <i className="ri-instagram-line"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogContent;
