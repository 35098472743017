import React from "react";

function HomeWhyChoose() {
  return (
    <>
      <div className="services-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Why EvenB Educational Consultant?</span>
            <h2>Please See All of EvenB Educational Consultant Services</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore.
            </p>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="services-box">
                <div className="d-flex align-items-center">
                  <i className="icon ri-user-add-line"></i>
                  <h3>College Info & Admission Guidance</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="services-box">
                <div className="d-flex align-items-center">
                  <i className="icon ri-book-open-line"></i>
                  <h3>Mentor For Entrance Exam</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="services-box">
                <div className="d-flex align-items-center">
                  <i className="icon ri-community-line"></i>
                  <h3>Direct Contact with College</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="services-box">
                <div className="d-flex align-items-center">
                  <i className="icon ri-bank-line"></i>
                  <h3>Education Loan Assistance</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="services-box">
                <div className="d-flex align-items-center">
                  <i className="icon ri-reserved-line"></i>
                  <h3>Seat Reservation</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeWhyChoose;
