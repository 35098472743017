import React from "react";
import CollegeSingleHeader from "../Components/CollegeSingle/CollegeSingleHeader";
import CollegeSingleContent from "../Components/CollegeSingle/CollegeSingleContent";
import CollegeSingleFreeQuote from "../Components/CollegeSingle/CollegeSingleFreeQuote";

function CollegeSingle() {
  return (
    <>
      <CollegeSingleHeader />
      <CollegeSingleContent />
      <CollegeSingleFreeQuote />
    </>
  );
}

export default CollegeSingle;
