import React from "react";

function HomeService() {
  return (
    <>
      <div className="services-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">SERVICES</span>
            <h2>Please See All of Our Services and All Other Details</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services1.jpg" alt="img" />
                  </a>
                </div>
                <div className="content text-center ">
                  <h3>
                    <a href="services-details.html">Educational</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut{" "}
                  </p>
                  <a href="services-1.html" className="default-btn ">
                    View More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services2.jpg" alt="img" />
                  </a>
                </div>
                <div className="content text-center">
                  <h3 className="text-center">
                    <a href="services-details.html">Media</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="services-2.html" className="default-btn">
                    View More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services3.jpg" alt="img" />
                  </a>
                </div>
                <div className="content text-center">
                  <h3 className="text-center">
                    <a href="services-details.html">Architecture</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="services-1.html" className="default-btn">
                    View More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeService;
