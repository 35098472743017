import Navbar from "./Components/Navigation/Header/Navbar";
import Footer from "./Components/Navigation/Footer/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import ServiceEducation from "./Pages/ServiceEducation";
import ServiceEduSingle from "./Pages/ServiceEduSingle";
import ServiceMedia from "./Pages/ServiceMedia";
import ServiceArch from "./Pages/ServiceArch";
import CollegeSingle from "./Pages/CollegeSingle";
import Abroad from "./Pages/Abroad";
import Blogs from "./Pages/Blogs";
import BlogSingle from "./Pages/BlogSingle";
import Contact from "./Pages/Contact";

import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/service-education">
          <ServiceEducation />
        </Route>
        <Route path="/service-education-single">
          <ServiceEduSingle />
        </Route>
        <Route path="/service-media">
          <ServiceMedia />
        </Route>
        <Route path="/service-arch">
          <ServiceArch />
        </Route>
        <Route path="/college-single">
          <CollegeSingle />
        </Route>
        <Route path="/abroad">
          <Abroad />
        </Route>
        <Route path="/blogs">
          <Blogs />
        </Route>
        <Route path="/blog-single">
          <BlogSingle />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
