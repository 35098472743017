import React from "react";

function BlogPopularPost() {
  return (
    <>
      <div className="col-lg-4 col-md-12">
        <aside className="widget-area">
          <div className="widget widget_enry_posts_thumb">
            <h3 className="widget-title">Popular Posts</h3>
            <article className="item">
              <a href="blog-details.html" className="thumb">
                <span className="fullimage cover bg1" role="img"></span>
              </a>
              <div className="info">
                <h4 className="title usmall">
                  <a href="blog-details.html">
                    Being The Best-Selling Smart Phone This Year
                  </a>
                </h4>
                <span className="date">
                  <i className="ri-calendar-2-fill"></i> Jan 15, 2020
                </span>
              </div>
            </article>
            <article className="item">
              <a href="blog-details.html" className="thumb">
                <span className="fullimage cover bg2" role="img"></span>
              </a>
              <div className="info">
                <h4 className="title usmall">
                  <a href="blog-details.html">
                    Love Songs Helped Me Through Heartbreak
                  </a>
                </h4>
                <span className="date">
                  <i className="ri-calendar-2-fill"></i> Jan 14, 2020
                </span>
              </div>
            </article>
            <article className="item">
              <a href="blog-details.html" className="thumb">
                <span className="fullimage cover bg3" role="img"></span>
              </a>
              <div className="info">
                <h4 className="title usmall">
                  <a href="blog-details.html">
                    Two Fashion Designers Busy With 2020 Winter Fashion
                  </a>
                </h4>
                <span className="date">
                  <i className="ri-calendar-2-fill"></i> Jan 13, 2020
                </span>
              </div>
            </article>
            <article className="item">
              <a href="blog-details.html" className="thumb">
                <span className="fullimage cover bg4" role="img"></span>
              </a>
              <div className="info">
                <h4 className="title usmall">
                  <a href="blog-details.html">
                    Working in the Office is a Tradition For Women
                  </a>
                </h4>
                <span className="date">
                  <i className="ri-calendar-2-fill"></i> Jan 12, 2020
                </span>
              </div>
            </article>
          </div>
        </aside>
      </div>
    </>
  );
}

export default BlogPopularPost;
