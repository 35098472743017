import React from "react";
import ContactHeader from "../Components/Contact/ContactHeader";
import ContactDetails from "../Components/Contact/ContactDetails";
import ContactForm from "../Components/Contact/ContactForm";

function Contact() {
  return (
    <>
      <ContactHeader />

      <div class="contact-area ptb-100">
        <div class="container">
          <ContactDetails />
          <ContactForm />
        </div>
      </div>
    </>
  );
}

export default Contact;
