import React from "react";

function HomeAbout() {
  return (
    <>
      <div className="why-choose-us-area pt-100 bg-fbf9f7">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-us-image bg-image">
                <img src="assets/img/why-choose-us.jpg" alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-us-content">
                <span className="sub-title">ABOUT ALL OF US</span>
                <h2>Reason of Choice Us From All of The Competitor in World</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy ant extra eirmod te mpor invidunt ut labore et
                  dolore magna aliquyam erat.
                </p>
                <ul className="choose-list">
                  <li>
                    <i className="ri-rocket-2-line"></i>
                    <h3>Mission</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy ant extra mpor invidunt ut labore et
                      dolore magna aliquyam erat.
                    </p>
                  </li>
                  <li>
                    <i className="ri-settings-2-line"></i>
                    <h3>Vision</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy ant extra mpor invidunt ut labore et
                      dolore magna aliquyam erat.
                    </p>
                  </li>
                  <li>
                    <i className="ri-bar-chart-horizontal-line"></i>
                    <h3>Values</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy ant extra mpor invidunt ut labore et
                      dolore magna aliquyam erat.
                    </p>
                  </li>
                </ul>
                <a href="about-simple.html" className="default-btn">
                  Read More <i className="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeAbout;
