import React from "react";
import AboutHeader from "../Components/About/AboutHeader";
import AboutAboutUs from "../Components/About/AboutAboutUs";
import AboutFunFacts from "../Components/About/AboutFunFacts";
import AboutMission from "../Components/About/AboutMission";
import AboutTestimonials from "../Components/About/AboutTestimonials";
import AboutFreeQuote from "../Components/About/AboutFreeQuote";

function About() {
  return (
    <>
      <AboutHeader />
      <AboutAboutUs />
      <AboutFunFacts />
      <AboutMission />
      <AboutTestimonials />
      <AboutFreeQuote />
    </>
  );
}

export default About;
