import React from "react";

function ServiceEduSingleFreeQuote() {
  return (
    <>
      <div className="free-quote-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="free-quote-image">
                <img src="assets/img/free-quote.jpg" alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="free-quote-text">
                <span className="sub-title">FREE QUOTE</span>
                <h2>Search and Expert Any Time From Just Knock Us</h2>
                <form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Your Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Mobile Number</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Place</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Your Email</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Featured Courses</label>
                        <select className="form-select">
                          <option selected>Medical</option>
                          <option>Engineering</option>
                          <option>Management</option>
                          <option>Travel & Tourism</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Courses</label>
                        <select className="form-select">
                          <option selected>Under Graduation</option>
                          <option>Post Graduation</option>
                          <option>Diploma</option>
                          <option>Super Speciality</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Branch</label>
                        <select className="form-select">
                          <option selected>MBBS</option>
                          <option>BHMS</option>
                          <option>BUMS</option>
                          <option>BAMS</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <button type="submit" className="default-btn">
                          Request Call Back{" "}
                          <i className="ri-arrow-right-line"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
    </>
  );
}

export default ServiceEduSingleFreeQuote;
