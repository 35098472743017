import React from "react";
import AbroadHeader from "../Components/Abroad/AbroadHeader";
import AbroadCard from "../Components/Abroad/AbroadCard";

function Abroad() {
  return (
    <>
      <AbroadHeader />
      <AbroadCard />
    </>
  );
}

export default Abroad;
