import React from "react";

function AboutFunFacts() {
  return (
    <>
      <div className="funfacts-area bg-color">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-3 col-md-3 col-6">
              <div className="single-funfacts-box">
                <h3>
                  <span className="odometer" data-count="40">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Colleges</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-6">
              <div className="single-funfacts-box">
                <h3>
                  <span className="odometer" data-count="10">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Admission</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-6">
              <div className="single-funfacts-box">
                <h3>
                  <span className="odometer" data-count="12">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Branches</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-6">
              <div className="single-funfacts-box">
                <h3>
                  <span className="odometer" data-count="78">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Students</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutFunFacts;
