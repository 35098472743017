/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

function ContactForm() {
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46660.669043361966!2d-76.17429939609431!3d43.03529129888566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3b8019f2991%3A0x58d5929e21a62e5!2sDinosaur%20Bar-B-Que!5e0!3m2!1sen!2sbd!4v1593527523138!5m2!1sen!2sbd"></iframe>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="contact-form">
            <span className="sub-title">SEND MESSAGE</span>
            <h2>Lets Provide Us a Message & Contact Us</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy liquyam erat, sed diam voluptua. At vero eos et accusam et
              justo duo dolo lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy liquyam erat, sed diam voluptua.
            </p>
            <form id="contactForm">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      required
                      data-error="Please enter your name"
                      placeholder="Full Name"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      required
                      data-error="Please enter your email"
                      placeholder="Email Address"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone_number"
                      className="form-control"
                      id="phone_number"
                      required
                      data-error="Please enter your phone number"
                      placeholder="Mobile no"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="msg_subject"
                      className="form-control"
                      id="msg_subject"
                      placeholder="Subject"
                      required
                      data-error="Please enter your subject"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      cols="30"
                      rows="6"
                      required
                      data-error="Please enter your message"
                      placeholder="Message..."
                    ></textarea>
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <button type="submit" className="default-btn">
                    Send Message <i className="ri-arrow-right-line"></i>
                  </button>
                  <div id="msgSubmit" className="h3 text-center hidden"></div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
