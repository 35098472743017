import React from "react";

function ServiceMediaContact() {
  return (
    <>
      <div className="contact-area ptb-100">
        <div className="container">
          <div className="row text-center pb-100">
            <div className="col-lg-12 col-md-12">
              <div className="contact-content">
                <span className="sub-title">CONTACT US</span>
                <h2>Contact Us With Your Details & Ready To Start</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod liquyam erat, sed diam voluptua. At vero
                  eos et accusam et justo duo dolores et ea lorem ipsum dolor
                  sit amet, consetetur sadipscing elitr.
                </p>
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="single-contact-info-box">
                      <div className="icon service-icon">
                        <i className="ri-home-7-line"></i>
                      </div>
                      <h3>Main Office</h3>
                      <p>325 Rockford Lane Durham, New York, USA</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="single-contact-info-box">
                      <div className="icon service-icon">
                        <i className="ri-phone-line"></i>
                      </div>
                      <h3>Our Phone</h3>
                      <p>
                        <span>Call:</span>
                        <a href="/cdn-cgi/l/email-protection#200b1118111414181212121916">
                          +(1) 814 482 2296
                        </a>
                      </p>
                      <p>
                        <span>Call:</span>
                        <a href="/cdn-cgi/l/email-protection#4a617f7e7d72737c79787b7f7d">
                          +(5) 478 963 2157
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="single-contact-info-box">
                      <div className="icon service-icon">
                        <i className="ri-mail-star-line"></i>
                      </div>
                      <h3>Email Address</h3>
                      <p>
                        <span>Email:</span>
                        <a href="/cdn-cgi/l/email-protection#bad2dfd6d6d5fadfd4c8c394d9d5d7">
                          <span
                            className="__cf_email__"
                            data-cfemail="b0d8d5dcdcdff0d5dec2c99ed3dfdd"
                          >
                            [email&#160;protected]
                          </span>
                        </a>
                      </p>
                      <p>
                        <span>Email:</span>
                        <a href="/cdn-cgi/l/email-protection#6d04030b022d08031f14430e0200">
                          <span
                            className="__cf_email__"
                            data-cfemail="83eaede5ecc3e6edf1faade0ecee"
                          >
                            [email&#160;protected]
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceMediaContact;
