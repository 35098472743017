import React from "react";
import BlogHeader from "../Components/Blogs/BlogHeader";
import BlogCard from "../Components/Blogs/BlogCard";

function Blogs() {
  return (
    <>
      <BlogHeader />
      <BlogCard />
    </>
  );
}

export default Blogs;
