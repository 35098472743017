import React from "react";

function HomeFunFacts() {
  return (
    <>
      <div className="funfacts-area pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">FUN FACTS</span>
            <h2>Let's Meet Up With Our Fun Facts And Introduce To Us</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-3 col-6 col-md-3">
              <div className="funfacts-box">
                <i className="ri-file-copy-2-line"></i>
                <h3>
                  <span className="odometer" data-count="40">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Colleges</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-6 col-md-3">
              <div className="funfacts-box">
                <i className="ri-book-open-line"></i>
                <h3>
                  <span className="odometer" data-count="10">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Admission</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-6 col-md-3">
              <div className="funfacts-box">
                <i className="ri-star-smile-line"></i>
                <h3>
                  <span className="odometer" data-count="12">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Branches</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-6 col-md-3">
              <div className="funfacts-box">
                <i className="ri-user-settings-line"></i>
                <h3>
                  <span className="odometer" data-count="78">
                    00
                  </span>
                  <span className="sign">K</span>
                </h3>
                <p>Students</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeFunFacts;
