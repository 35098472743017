import React from "react";
import ServiceEduSingleAccordion from "./ServiceContnet/ServiceEduSingleAccordion";
import ServiceEduSingleCategory from "./ServiceContnet/ServiceEduSingleCategory";

function ServiceEduSingleContent() {
  return (
    <>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h3>Medical</h3>
                <img src="assets/img/services/services3.jpg" alt="img" />
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam <strong>nonumy</strong>
                  eirmod tempor invidunt ut labore et dolore magna aliquyam
                  erat, sed diam voluptua. At vero eos et accusam et justo duo
                  dolores et ea rebum. Stet clita kasd gubergren, no sea
                  takimata sanctus.
                </p>
                <ServiceEduSingleAccordion />
              </div>
            </div>

            <ServiceEduSingleCategory />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceEduSingleContent;
