import React from "react";

function HomePartners() {
  return (
    <>
      <div className="partner-area pt-100">
        <div className="container">
          <div className="partner-slides owl-carousel owl-theme">
            <div className="single-partner-item">
              <a href="/" className="d-inline-block">
                <img src="assets/img/partner/partner1.png" alt="img" />
              </a>
            </div>
            <div className="single-partner-item">
              <a href="/" className="d-inline-block">
                <img src="assets/img/partner/partner2.png" alt="img" />
              </a>
            </div>
            <div className="single-partner-item">
              <a href="/" className="d-inline-block">
                <img src="assets/img/partner/partner3.png" alt="img" />
              </a>
            </div>
            <div className="single-partner-item">
              <a href="/" className="d-inline-block">
                <img src="assets/img/partner/partner4.png" alt="img" />
              </a>
            </div>
            <div className="single-partner-item">
              <a href="/" className="d-inline-block">
                <img src="assets/img/partner/partner5.png" alt="img" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePartners;
