import React from "react";
import BlogHeader from "../Components/Blogs/BlogSingle/BlogHeader";
import BlogContent from "../Components/Blogs/BlogSingle/BlogContent";
import BlogPopularPost from "../Components/Blogs/BlogSingle/BlogPopularPost";

function BlogSingle() {
  return (
    <>
      <BlogHeader />
      <section class="blog-details-area ptb-100">
        <div class="container">
          <div class="row">
            <BlogContent />
            <BlogPopularPost />
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogSingle;
