import React from "react";

function HomeBanner() {
  return (
    <>
      <div className="home-slides owl-carousel owl-theme">
        <div className="single-banner-item bg1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="banner-item-content">
                  <span className="sub-title">WELCOME TO US</span>
                  <h1>Business Consultant and Grid Line For You</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam intaas nonumy eirmod tempor invidunt ut labore et.
                  </p>
                  <div className="btn-box">
                    <a href="contact.html" className="default-btn">
                      Contact Us <i className="ri-arrow-right-line"></i>
                    </a>
                    <a href="tel:+919961755558" className="default-btn">
                      Call Us <i className="ri-phone-line"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="banner-item-image">
                  <img src="assets/img/banner/banner2.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-banner-item bg2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="banner-item-content">
                  <span className="sub-title">ABOUT ALL OF US</span>
                  <h1>Take Our Help To Reach The Top</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam intaas nonumy eirmod tempor invidunt ut labore et.
                  </p>
                  <div className="btn-box">
                    <a href="contact.html" className="default-btn">
                      Contact Us <i className="ri-arrow-right-line"></i>
                    </a>
                    <a href="tel:+919961755558" className="default-btn">
                      Call Us <i className="ri-phone-line"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="banner-item-image">
                  <img src="assets/img/banner/banner3.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-banner-item bg3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="banner-item-content">
                  <span className="sub-title">REASON OF CHOOSE US</span>
                  <h1>Transform and Build Your Own Business</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam intaas nonumy eirmod tempor invidunt ut labore et.
                  </p>
                  <div className="btn-box">
                    <a href="contact.html" className="default-btn">
                      Contact Us <i className="ri-arrow-right-line"></i>
                    </a>
                    <a href="tel:+919961755558" className="default-btn">
                      Call Us <i className="ri-phone-line"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="banner-item-image">
                  <img src="assets/img/banner/banner4.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeBanner;
