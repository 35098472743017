import React from "react";
import ServiceArchHeader from "../Components/ServiceArch/ServiceArchHeader";
import ServiceArchWhatWe from "../Components/ServiceArch/ServiceArchWhatWe";
import ServiceArchServices from "../Components/ServiceArch/ServiceArchServices";
import ServiceArchProjects from "../Components/ServiceArch/ServiceArchProjects";
import ServiceArchContact from "../Components/ServiceArch/ServiceArchContact";

function ServiceArch() {
  return (
    <>
      <ServiceArchHeader />
      <ServiceArchWhatWe />
      <ServiceArchServices />
      <ServiceArchProjects />
      <ServiceArchContact />
    </>
  );
}

export default ServiceArch;
