import React from "react";

function BlogCard() {
  return (
    <>
      <div className="blog-area ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-post">
                <div className="post-image">
                  <a href="blog-details.html" className="d-block">
                    <img src="assets/img/blog/blog1.jpg" alt="img" />
                  </a>
                </div>
                <div className="post-content">
                  <ul className="meta">
                    <li>
                      <i className="ri-calendar-2-line"></i> Jan 22, 2021
                    </li>
                    <li>
                      <i className="ri-user-voice-line"></i>{" "}
                      <a href="blog-grid.html">Lords Evans</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      The Secret of Your Business Success Find Quickly
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut.
                  </p>
                  <a href="blog-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-post">
                <div className="post-image">
                  <a href="blog-details.html" className="d-block">
                    <img src="assets/img/blog/blog2.jpg" alt="img" />
                  </a>
                </div>
                <div className="post-content">
                  <ul className="meta">
                    <li>
                      <i className="ri-calendar-2-line"></i> Jan 20, 2021
                    </li>
                    <li>
                      <i className="ri-user-voice-line"></i>{" "}
                      <a href="blog-grid.html">Sarah Taylor</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      Consulting is a Good and Best Into Our Company
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut.
                  </p>
                  <a href="blog-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-post">
                <div className="post-image">
                  <a href="blog-details.html" className="d-block">
                    <img src="assets/img/blog/blog3.jpg" alt="img" />
                  </a>
                </div>
                <div className="post-content">
                  <ul className="meta">
                    <li>
                      <i className="ri-calendar-2-line"></i> Jan 18, 2021
                    </li>
                    <li>
                      <i className="ri-user-voice-line"></i>{" "}
                      <a href="blog-grid.html">James Andy</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      Business Has Become a Good in the Global World
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut.
                  </p>
                  <a href="blog-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-post">
                <div className="post-image">
                  <a href="blog-details.html" className="d-block">
                    <img src="assets/img/blog/blog4.jpg" alt="img" />
                  </a>
                </div>
                <div className="post-content">
                  <ul className="meta">
                    <li>
                      <i className="ri-calendar-2-line"></i> Jan 16, 2021
                    </li>
                    <li>
                      <i className="ri-user-voice-line"></i>{" "}
                      <a href="blog-grid.html">James Andy</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      Business Has Become a Good in the Global World
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut.
                  </p>
                  <a href="blog-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogCard;
