import React from "react";

function AboutMission() {
  return (
    <>
      <div className="mission-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-image bg-image">
                <img src="assets/img/about/about3.jpg" alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h2>Way Of Our Work In At a Glance & All Other Details?</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy ant extra eirmo tempor invidunt ut labore et
                  dolore magna aliquyam erat, sed diammi maxil voluptua. At ve
                  ro eos et accusam et justo duo dolores et ea rebum.
                </p>
                <ul className="features-list">
                  <li>
                    <div className="number">01</div>
                    <h3>Market Research Step By Step</h3>
                    <p>
                      Extra mpor invidunt ut labore et dolore magna aliquyam
                      erat.
                    </p>
                  </li>
                  <li>
                    <div className="number">02</div>
                    <h3>Consulting & Discussing</h3>
                    <p>
                      Extra mpor invidunt ut labore et dolore magna aliquyam
                      erat.
                    </p>
                  </li>
                  <li>
                    <div className="number">03</div>
                    <h3>Project Management</h3>
                    <p>
                      Extra mpor invidunt ut labore et dolore magna aliquyam
                      erat.
                    </p>
                  </li>
                  <li>
                    <div className="number">04</div>
                    <h3>Final Output Gain</h3>
                    <p>
                      Extra mpor invidunt ut labore et dolore magna aliquyam
                      erat.
                    </p>
                  </li>
                </ul>
                <a href="contact.html" className="default-btn">
                  Contact Us <i className="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMission;
