import React from "react";

function ServiceEducationCards() {
  return (
    <>
      <div className="services-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">SERVICES</span>
            <h2>All The Services That We Provide to Our Clients</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services1.jpg" alt="img" />
                  </a>
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Medical</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="services-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services2.jpg" alt="img" />
                  </a>
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Engineering</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="services-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services3.jpg" alt="img" />
                  </a>
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Management</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="services-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services4.jpg" alt="img" />
                  </a>
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Travel & Tourism</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="services-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="image">
                  <a href="services-details.html">
                    <img src="assets/img/services/services5.jpg" alt="img" />
                  </a>
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Agricultural</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="services-details.html" className="default-btn">
                    Read More <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceEducationCards;
