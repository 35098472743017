import React from "react";

function BlogHeader() {
  return (
    <>
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Blog Grid</h2>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Blog Grid</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogHeader;
