import React from "react";

function ContactDetails() {
  return (
    <>
      <div className="row align-items-center pb-100">
        <div className="col-lg-6 col-md-12">
          <div className="contact-content">
            <span className="sub-title">CONTACT US</span>
            <h2>Contact Us With Your Details & Ready To Start</h2>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="single-contact-info-box">
                  <div className="icon ">
                    <i className="ri-home-7-line"></i>
                  </div>
                  <h3>Office</h3>
                  <p>325 Rockford Lane Durham, New York, USA</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="single-contact-info-box">
                  <div className="icon">
                    <i className="ri-phone-line"></i>
                  </div>
                  <h3>Phone</h3>
                  <p>
                    <span>Call:</span>
                    <a href="/cdn-cgi/l/email-protection#200b1118111414181212121916">
                      +(1) 814 482 2296
                    </a>
                  </p>
                  <p>
                    <span>Call:</span>
                    <a href="/cdn-cgi/l/email-protection#4a617f7e7d72737c79787b7f7d">
                      +(5) 478 963 2157
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="single-contact-info-box">
                  <div className="icon">
                    <i className="ri-mail-star-line"></i>
                  </div>
                  <h3>Email</h3>
                  <p>
                    <span>Email:</span>
                    <a href="/cdn-cgi/l/email-protection#bad2dfd6d6d5fadfd4c8c394d9d5d7">
                      <span
                        className="__cf_email__"
                        data-cfemail="b0d8d5dcdcdff0d5dec2c99ed3dfdd"
                      >
                        [email&#160;protected]
                      </span>
                    </a>
                  </p>
                  <p>
                    <span>Email:</span>
                    <a href="/cdn-cgi/l/email-protection#6d04030b022d08031f14430e0200">
                      <span
                        className="__cf_email__"
                        data-cfemail="83eaede5ecc3e6edf1faade0ecee"
                      >
                        [email&#160;protected]
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="single-contact-info-box">
                  <div className="icon ">
                    <i className="ri-home-7-line"></i>
                  </div>
                  <h3>Branches</h3>
                  <p>Kerala | Bangalore | Tamil Nadu </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="contact-image">
            <img src="assets/img/contact.png" alt="img" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactDetails;
