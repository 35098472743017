import React from "react";

function ServiceEduSingleCategory() {
  return (
    <>
      <div className="col-lg-4 col-md-12">
        <aside className="widget-area">
          <div className="widget widget_service_categories">
            <h3 className="widget-title">Service Category</h3>
            <ul>
              <li>
                <a href="services-details.html">
                  Engineering <i className="ri-arrow-right-s-line"></i>
                </a>
              </li>
              <li>
                <a href="services-details.html">
                  Management <i className="ri-arrow-right-s-line"></i>
                </a>
              </li>
              <li>
                <a href="services-details.html">
                  Travel & Tourism <i className="ri-arrow-right-s-line"></i>
                </a>
              </li>
              <li>
                <a href="services-details.html">
                  Agricultural <i className="ri-arrow-right-s-line"></i>
                </a>
              </li>
              <li>
                <a href="services-details.html">
                  Aviation<i className="ri-arrow-right-s-line"></i>
                </a>
              </li>
              <li>
                <a href="services-details.html">
                  Marine <i className="ri-arrow-right-s-line"></i>
                </a>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </>
  );
}

export default ServiceEduSingleCategory;
