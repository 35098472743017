import React from "react";

function AbroadCard() {
  return (
    <>
      <div className="projects-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Study in Abroad Services</span>
            <h2>You Can Check Our Services as Inspirations</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div>
                  <img src="assets/img/services/services1.jpg" alt="img" />
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Study in UK</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="contact.html" className="default-btn">
                    Contact Us <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div>
                  <img src="assets/img/services/services1.jpg" alt="img" />
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Study in UK</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="contact.html" className="default-btn">
                    Contact Us <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div>
                  <img src="assets/img/services/services1.jpg" alt="img" />
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Study in UK</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="contact.html" className="default-btn">
                    Contact Us <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div>
                  <img src="assets/img/services/services1.jpg" alt="img" />
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Study in UK</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="contact.html" className="default-btn">
                    Contact Us <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div>
                  <img src="assets/img/services/services1.jpg" alt="img" />
                </div>
                <div className="content">
                  <h3>
                    <a href="services-details.html">Study in UK</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, conseteturants atal into
                    sadipscing elitr, sed diam nonumy eirmod nsa ada tempor
                    invidunt ut
                  </p>
                  <a href="contact.html" className="default-btn">
                    Contact Us <i className="ri-arrow-right-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AbroadCard;
