import React from "react";

function CollegeSingleHeader() {
  return (
    <>
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Colleges</h2>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <a href="services-1.html">Services</a>
              </li>
              <li>Colleges</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollegeSingleHeader;
