import React from "react";

function ServiceEduSingleAccordion() {
  return (
    <>
      <div className="faq-area ptb-50">
        <div className="container">
          <div className="faq-accordion accordion" id="faqAccordion">
            <div className="accordion-item">
              <button
                className="accordion-header accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Under Graduation
              </button>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  <ul>
                    <li>MBBS - Allopathy</li>
                    <li>BUMS - Unani</li>
                    <li>BHMS - Ayurveda</li>
                    <li>BAMS - Siddha</li>
                    <li>BNYS - Naturopathy</li>
                    <li>BDS - Dental</li>
                    <li>BVSc - Veterinary</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <button
                className="accordion-header accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Post Graduation
              </button>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  <ul>
                    <li>MBBS - Allopathy</li>
                    <li>BUMS - Unani</li>
                    <li>BHMS - Ayurveda</li>
                    <li>BAMS - Siddha</li>
                    <li>BNYS - Naturopathy</li>
                    <li>BDS - Dental</li>
                    <li>BVSc - Veterinary</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <button
                className="accordion-header accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Diploma
              </button>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  <ul>
                    <li>MBBS - Allopathy</li>
                    <li>BUMS - Unani</li>
                    <li>BHMS - Ayurveda</li>
                    <li>BAMS - Siddha</li>
                    <li>BNYS - Naturopathy</li>
                    <li>BDS - Dental</li>
                    <li>BVSc - Veterinary</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <button
                className="accordion-header accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                Super Speciality Courses
              </button>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  <ul>
                    <li>MBBS - Allopathy</li>
                    <li>BUMS - Unani</li>
                    <li>BHMS - Ayurveda</li>
                    <li>BAMS - Siddha</li>
                    <li>BNYS - Naturopathy</li>
                    <li>BDS - Dental</li>
                    <li>BVSc - Veterinary</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceEduSingleAccordion;
