import React from "react";

function ServiceMediaService() {
  return (
    <>
      <div className="services-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">SERVICES</span>
            <h2>All The Services That We Provide to Our Clients</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-item">
                <div className="icon">
                  <i className="ri-group-2-line"></i>
                </div>
                <h3>Branding</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetet elitr, sed diam nonumy
                  eiod temp labore et dolore magna.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-item">
                <div className="icon">
                  <i className="ri-briefcase-line"></i>
                </div>
                <h3>Digital Marketing</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetet elitr, sed diam nonumy
                  eiod temp labore et dolore magna.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-item">
                <div className="icon">
                  <i className="ri-money-dollar-box-line"></i>
                </div>
                <h3>Web Development</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetet elitr, sed diam nonumy
                  eiod temp labore et dolore magna.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-item">
                <div className="icon">
                  <i className="ri-settings-2-line"></i>
                </div>
                <h3>Video & Audio Editing</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetet elitr, sed diam nonumy
                  eiod temp labore et dolore magna.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-item">
                <div className="icon">
                  <i className="ri-restart-line"></i>
                </div>
                <h3>UI/UX Design</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetet elitr, sed diam nonumy
                  eiod temp labore et dolore magna.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-item">
                <div className="icon">
                  <i className="ri-lightbulb-flash-line"></i>
                </div>
                <h3>Animation</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetet elitr, sed diam nonumy
                  eiod temp labore et dolore magna.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceMediaService;
